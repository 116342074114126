import React from "react"
import { Stack, Text } from "@chakra-ui/layout"
import { Tooltip } from "@chakra-ui/react"
import { graphql, Link } from "gatsby"
import { useStaticQuery } from "gatsby"

interface IInterestsCard {
    placement: number;
    title: string;
    interest: {
        emoji: string;
        label: string;
    };
    border: {
        right: any;
        bottom: any;
        left: any;
        top: any;
    }
}

const InterestsCard: React.FC<IInterestsCard> = ({title, interest, border, placement}) => {
    let link = '/bazinga/we-like-pizza'
    const data = useStaticQuery(graphql`
        query {
            allContentfulGif{
                edges {
                    node {
                        slug
                        type
                    }
                }
            }
        }
    `)

    if (placement === 1) {
        const nodes = data.allContentfulGif.edges
            .filter((el) => el.node.type === "Pizza")
        if (nodes.length > 0) {
            link = `/bazinga/${nodes[Math.floor(Math.random() * nodes.length)].node.slug}`
        }
    } else if (placement === 2) {
        const nodes = data.allContentfulGif.edges
            .filter((el) => el.node.type === "Programming")
        if (nodes.length > 0) {
            link = `/bazinga/${nodes[Math.floor(Math.random() * nodes.length)].node.slug}`
        }
    } else {
        const nodes = data.allContentfulGif.edges
            .filter((el) => el.node.type === "Chocolates")
        if (nodes.length > 0) {
            link = `/bazinga/${nodes[Math.floor(Math.random() * nodes.length)].node.slug}`
        }
    }

    return (
        <Link to={link}>
            <Tooltip label="Don't click me" placement={(placement === 1 || placement === 2) ? "top-start" :  "right-end"} hasArrow>
                <Stack as="div"
                    padding={6}
                    background="antiquewhite"
                    borderBottom={border.bottom}
                    borderTop={border.top}
                    borderLeft={border.left}
                    borderRight={border.right}
                >
                    <Text as="p"
                        textTransform="uppercase"
                        marginBottom={6}
                        fontWeight={600}
                        fontSize="xl"
                        background="transparent"
                        color="black"
                    >
                        {title}
                    </Text>
                    <Text as="span" role="img" aria-label={interest.label}
                        fontSize="2xl"
                        background="transparent"
                    >
                    {interest.emoji}
                    </Text>
                </Stack>

            </Tooltip>
        </Link>
    )
}

// Take to giphy-inspired pagee
// <div style={{
//     width:"100%",
//     height:0,
//     paddingBottom:"83%",
//     position:"relative",

//   }}><iframe src="https://giphy.com/embed/jn2iXu2HRpMuovBrrV" width="100%" height="100%" style={{position:"absolute"}} frameBorder="0" className="giphy-embed" allowFullScreen></iframe></div><p><a href="https://giphy.com/gifs/Friends-season-6-the-one-with-unagi-episode-617-jn2iXu2HRpMuovBrrV">via GIPHY</a></p>
//   <div>

export default InterestsCard
